<template>
  <div>
    <div class="row success">
      <div class="col-xl-8">
        <h5 style="margin-bottom: 30px">
          <span style="margin-right: 30px">{{
            $t("bulletin.certByMoyenne")
          }}</span>
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4">
        <label>{{ $t("bulletin.listeClasses") }}</label>
        <a-select
          show-search
          :placeholder="$t('emploi.listeClasses')"
          option-filter-prop="children"
          :filter-option="filterOption"
          style="width: 150px; margin-left: 10px"
          @change="
            (val) => {
              handleClassChange('classe', val);
            }
          "
        >
          <a-select-option value="all">---</a-select-option>
          <a-select-option
            v-for="classe in classes"
            :key="classe._id"
            :value="classe._id"
            >{{ classe.name }}</a-select-option
          >
        </a-select>
      </div>

      <div class="col-xl-4">
        <label style="margin-right: 5px">{{
          this.$t("emploi.trimestre")
        }}</label>
        <a-select
          :placeholder="$t('paiement.selectionnerMois')"
          style="width: 100px; margin-left: 15px; margin-right: 20px"
          @change="
            (val) => {
              this.selectedTrimester = val;
              this.updateStudentMoy();
            }
          "
          default-value="1"
        >
          <a-select-option
            v-for="trimester in ['1', '2', '3']"
            :key="trimester"
            :value="trimester"
            >{{ trimester }}</a-select-option
          >
        </a-select>
      </div>

      <div
        class="col-xl-4"
        style="display: flex; justify-content: end"
        v-if="selectedTrimester && selectedClasse"
      >
        <a-button
          type="primary"
          :loading="printLoading"
          :disabled="printLoading"
          @click="handelOpen()"
        >
          <a-icon v-if="!printLoading" type="file-pdf" />
          {{ $t("action.imprimer") }}
        </a-button>
      </div>
    </div>
    <div class="py-3"></div>
    <div class="row">
      <div class="col-xl-12">
        <div class="car" v-if="selectedTrimester && selectedClasse">
          <div class="data-table">
            <a-table
              @change="tableChanged"
              :pagination="false"
              :data-source="bulletinData"
              :columns="columns"
              :loading="tableLoading"
            >
              <template slot="moyenne" slot-scope="text">
                <span>{{ text }}</span>
              </template>

              <template slot="certificate" slot-scope="text, record">
                <span>{{
                  record.moyenne
                    ? getCertificationNameByMoyenne(record.moyenne)
                    : "--"
                }}</span>
              </template>

              <div
                slot="filterDropdown"
                slot-scope="{
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                  column,
                }"
                style="padding: 8px"
              >
                <a-input
                  v-ant-ref="(c) => (searchInput = c)"
                  :placeholder="`${$t('personnel.chercher')} ${column.title}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block"
                  @change="
                    (e) =>
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                  "
                  @pressEnter="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                />
                <a-button
                  type="primary"
                  icon="search"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                  >{{ $t("paiement.chercher") }}</a-button
                >
                <a-button
                  size="small"
                  style="width: 90px"
                  @click="() => handleReset(clearFilters)"
                  >{{ $t("action.reinitialiser") }}</a-button
                >
              </div>
              <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
            </a-table>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      @ok="printCertificates()"
      @cancel="handelCancel()"
      v-model="visibleBulletinDate"
      :title="$t('bulletin.date')"
      :closable="false"
      :width="500"
      :height="700"
      :key="'bulletinDate'"
    >
      <div>
        <label style="margin-right: 5px">{{ $t("bulletin.date") }}</label>
        <a-date-picker
          :allowClear="false"
          @change="
            (val) => {
              bulletinDate = val;
            }
          "
          :default-value="moment(new Date())"
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import apiClient from "@/services/axios";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export default {
  name: "Certificate",
  computed: {
    ...mapState(["settings", "user"]),
  },

  data() {
    return {
      printLoading: false,
      certifParams: [],
      classes: [],
      tableLoading: false,
      studentLoading: false,
      students: [],
      selectedClasse: null,
      selectedClassName: null,
      selectedTrimester: "1",
      visibleBulletinDate: false,
      bulletinDate: new Date(),
      filtredTable: [],
      columns: [
        {
          title: this.$t("inscription.nom"),
          dataIndex: "fullName",
          key: "fullName",
          scopedSlots: {
            customRender: "fullName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.fullName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("certification.moyenne"),
          dataIndex: "moyenne",
          scopedSlots: { customRender: "moyenne" },
        },
        {
          title: this.$t("menu.certificate"),
          dataIndex: "certificate",
          scopedSlots: { customRender: "certificate" },
        },
      ],
      bulletinData: [],
    };
  },

  async created() {
    this.tableLoading = true;

    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
            level: 1,
            bulletin: 1,
          },
        },
      })
      .then(({ data }) => (this.classes = data));

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];
    let { start, end } = this.settings.schoolarYears.find(
      (el) => el._id == this.settings.activeSchoolarYear
    );

    start = new Date(start).getFullYear();
    end = new Date(end).getFullYear();

    this.schoolarYearName = end + " / " + start;
    this.schoolType = this.schoolDetails.type;

    await apiClient
      .get("/certification/peda")
      .then((res) => {
        this.certifParams = res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  mounted() {},

  methods: {
    async handleClassChange(on, value) {
      this.studentLoading = true;
      this.selectedClasse = value;
      this.selectedClassName = this.classes.find((item) => item._id === value);
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] = value;

      await this.updateStudentMoy();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async updateStudentMoy() {
      this.tableLoading = true;
      this.printLoading = true;
      await apiClient
        .get(
          "/bulletin/v2/bt/MP/all/" +
            this.selectedClasse +
            "/" +
            this.selectedTrimester
        )
        .then(({ data }) => {
          if (data.students) {
            this.bulletinData = data.students;
            this.filtredTable = data.students;
          }
        })
        .catch((error) => {
          console.error("error ", error);
        })
        .finally(() => {
          this.tableLoading = false;
          this.printLoading = false;
        });
    },

    getCertificationNameByMoyenne(moyenne) {
      if (!moyenne) return "";
      for (const cert of this.certifParams)
        if (
          Number(moyenne) >= Number(cert.min) &&
          Number(moyenne) <= Number(cert.max)
        )
          return cert.name;
      return "";
    },

    getCertificationByMoyenne(moyenne) {
      for (const cert of this.certifParams)
        if (
          Number(moyenne) >= Number(cert.min) &&
          Number(moyenne) <= Number(cert.max)
        )
          return cert;
      return null;
    },

    printCertificates() {
      this.printLoading = true;
      this.visibleBulletinDate = false;
      setTimeout(() => {
        const doc = new JsPDF({
          unit: "mm",
          format: "a4",
        });

        const students = [];

        this.filtredTable.map((student) => {
          if (!student.moyenne) return;
          if (!this.getCertificationByMoyenne(student.moyenne)) return;
          students.push({
            ...student,
            certificate: this.getCertificationByMoyenne(student.moyenne),
          });
        });

        students.map((student, i) => {
          if (i !== 0) doc.addPage();
          this.drawCertifcate(doc, student);
        });

        doc.save("certificates-" + this.selectedClassName.name + ".pdf");
        this.printLoading = false;
      }, 10);
    },

    async drawCertifcate(doc, student) {
      try {
        let imageUrl = "./certificates/malekEssaghir/choker.png";
        if (!!student.certificate.template)
          imageUrl = `./certificates/${student.certificate.template}.png`;

        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();
        doc.addImage(imageUrl, "PNG", 0, 0, width, height);

        let fontSize = 23;
        doc.setFont("Al Jazeera Arabic", "Bold");
        doc.setFontSize(fontSize);
        doc.setTextColor(95, 65, 29); // RGB values for #5f411d
        let text = student.fullName;
        let textWidth = doc.getTextWidth(text);
        let textPosition = width / 2 - textWidth / 2;
        doc.text(text, textPosition, 166, {
          rtl: true,
        });
        /* let text1 = "يسر أسرة التربية و التعليم بمجموعة مــالك الصغير الخاصة  ";
        let text2 = " أن تسند هذه الشّهـادة الى التلميذ (ة),";
        doc.text(text1, 30, 160, {
          rtl: true,
        });

        doc.text(text2, 50, 170, {
          rtl: true,
        });*/
        let trimester = "";
        switch (this.selectedTrimester) {
          case "1":
            trimester = "الأول";
            break;
          case "2":
            trimester = "الثاني";
            break;
          case "3":
            trimester = "الثالث";
            break;
        }
        doc.setFontSize(13);
        doc.text(trimester, 122, 201.3, {
          rtl: true,
        });

        textWidth = doc.getTextWidth(this.selectedClassName.name);

        doc.text(
          this.selectedClassName.name,
          93.3 + (142.6 - 93.3) / 2 - textWidth / 2,
          190,
          {
            rtl: true,
          }
        );

        text = this.schoolarYearName;
        doc.text(text, 55, 201.3, {
          rtl: true,
        });

        fontSize = 15;
        doc.setFontSize(fontSize);

        text = this.schoolDetails.headMaster;
        // textWidth = doc.getTextWidth(text);
        doc.text(text, 35, 230);

        //stamp
        let stamp = this.settings.base_url + "/" + this.schoolDetails.stamp;
        const stampImage = new Image(30);
        if (stamp) stampImage.src = stamp;
        if (stamp) {
          try {
            doc.addImage(stamp, "PNG", 40, 230, 40, 40);
          } catch (e) {
            console.log(e.message);
          }
        }
        //end stamp
      } catch (error) {
        console.log(error);
      }
    },
    drawCertifcateDefault(doc, student) {
      try {
        let imageUrl = "./certificates/certif-1.png";
        if (!!student.certificate.template)
          imageUrl = `./certificates/${student.certificate.template}.png`;

        const logoUrl = this.settings.image[0].logo;
        const arabicName = this.schoolDetails.ArabicName || "";

        doc.addImage(
          imageUrl,
          "PNG",
          0,
          0,
          doc.internal.pageSize.getWidth(),
          doc.internal.pageSize.getHeight()
        );

        /* Set Logo */
        const logo = new Image();
        logo.src = logoUrl;

        const logoX = (doc.internal.pageSize.getWidth() - 30) / 2;
        doc.addImage(logo, "PNG", logoX, 10, 30, 30);

        doc.setFont("PT Bold Heading", "Bold");
        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();

        let certificateName = student.certificate.name;
        let fontSize = 36;

        doc.setFontSize(fontSize);

        let textWidth = doc.getStringUnitWidth(certificateName);
        let textPosition = (width - textWidth) / 2;

        doc.text(certificateName, textPosition, 55, {
          align: "center",
          rtl: true,
        });

        fontSize = 28;
        doc.setFont("Amiri", "Bold");
        doc.setFontSize(fontSize);

        let text =
          student.fullName + "  :" + " (أسندت هذه الشّهادة إلى  التّلميذ)ة";
        textWidth = doc.getTextWidth(text);
        textPosition = (width - textWidth) / 2;
        doc.text(text, textPosition, 80, {
          rtl: true,
        });

        let trimester = "";
        switch (this.selectedTrimester) {
          case "1":
            trimester = "الثلاثي الأول";
            break;
          case "2":
            trimester = "الثلاثي االثاني";
            break;
          case "3":
            trimester = "الثلاثي االثالث";
            break;
        }

        text = `${this.selectedClassName.name} الاجتهاد)ا( في الدّراسة خلال ${trimester} المرسّم)ة( بالسّنة  `;
        textWidth = doc.getTextWidth(text);
        textPosition = (width - textWidth) / 2;
        doc.text(text, textPosition, 105, {
          rtl: true,
        });

        text = this.schoolarYearName + " من السنة الدّراسيّة";
        textWidth = doc.getTextWidth(text);
        textPosition = (width - textWidth) / 2;
        doc.text(text, textPosition, 130, {
          rtl: true,
        });

        fontSize = 16;
        doc.setFontSize(fontSize);

        text = "الجمهرية التونسية";
        textWidth = doc.getTextWidth(text);
        doc.text(text, width - textPosition + 15, 20, { rtl: true });

        text = "وزارة التربية";
        textWidth = doc.getTextWidth(text);
        doc.text(text, width - textPosition + 20, 30);

        text =
          " المندوبية الجهوية للتربية ب " +
          this.schoolDetails.delegeRegional.replace(/\d/g, "");
        textWidth = doc.getTextWidth(text);
        doc.text(text, width - textPosition, 40);

        text = " المدرسة الابتدائية الخاصّة " + arabicName;
        textWidth = doc.getTextWidth(text);
        doc.text(text, width - textPosition - 5, 50);

        fontSize = 20;
        doc.setFontSize(fontSize);

        text =
          this.schoolDetails.delegeRegional.replace(/\d/g, "") +
          "في  " +
          moment(this.bulletinDate).format("YYYY/MM/DD");
        textWidth = doc.getTextWidth(text);
        doc.text(text, textWidth - 20, height - 40);

        text = " مدير)ة( " + this.schoolDetails.headMaster;
        // textWidth = doc.getTextWidth(text);
        doc.text(text, textWidth - 25, height - 25);
        //stamp
        let stamp = this.settings.base_url + "/" + this.schoolDetails.stamp;
        const stampImage = new Image(30);
        if (stamp) stampImage.src = stamp;
        if (stamp) {
          try {
            doc.addImage(stamp, "PNG", textWidth - 25, height - 25, 40, 40);
          } catch (e) {
            console.log(e.message);
          }
        }
        //end stamp
      } catch (error) {
        console.log(error);
      }
    },

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },

    handelCancel() {
      this.visibleBulletinDate = false;
      this.printLoading = false;
    },

    handelOpen() {
      this.visibleBulletinDate = true;
      this.printLoading = true;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    moment,
  },
};
</script>
