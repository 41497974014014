/*eslint-disable */
//ANCHOR class/month filter to this view: DONE

import apiClient from "@/services/axios";
import { mapState } from "vuex";
import { notification, Modal } from "ant-design-vue";
import moment from "moment";
import ExcelJS from "exceljs";
const bulletinHeplers = {
  MP: require("../studentMarks/scripts/bulletinHeplers/peda").default,
  MT: require("../studentMarks/scripts/bulletinHeplers/specPilote").default,
  MS: require("../studentMarks/scripts/bulletinHeplers/specPilote").default,
};
import JsPDF from "jspdf";
import "jspdf-autotable";

export default {
  computed: {
    ...mapState(["settings", "user"]),
    bulletinType: function () {
      switch (this.type) {
        case "MP":
          return "bulletin";
        case "MT":
          return "bulletinSpec";
        case "MS":
          return "bulletinVerySpec";
      }
    },
    disabledFrom: function () {
      const subject = this.activeModule.subjects.find(
        (s) => s._id == this.selectedSubject
      );
      if (subject && subject.disabled)
        return {
          name: subject.fromModule ? subject.fromModule.name : "",
          type: subject.fromModule ? subject.fromModule.type : "",
        };
      else
        return {
          name: "",
          type: "",
        };
    },
    assignedTeacherName: function () {
      let teacherName = "";
      try {
        const teacher = this.teacherAccess.find((teacher) =>
          teacher.access.some(
            (access) =>
              // Check if teacher has access to this classroom
              access.classeRoom._id === this.selectedClasse &&
              // Check if teacher has access to this subject
              access.subjects.some((sub) => sub._id === this.selectedSubject)
          )
        );
        if (teacher) {
          teacherName = teacher.fullName;
        }
      } catch (error) {
        return "--";
      }
      return teacherName;
    },
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    subModuleList: {
      type: Array,
      required: true,
    },
  },
  destroyed() {
    window.removeEventListener("beforeunload", this.confirmLeaveReload);
  },
  async created() {
    // DONE
    window.addEventListener("beforeunload", this.confirmLeaveReload);
    this.tableLoading = true;
    let project = {
      _id: 1,
      name: 1,
      level: 1,
    };
    project[this.bulletinType] = 1;
    await apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: project,
        },
      })
      .then((res) => (this.classes = res.data));

    apiClient
      .get(`/certification/${this.type == "MP" ? "peda" : "spec"}`)
      .then((res) => {
        this.certifParams = res.data;
      })
      .catch((e) => {
        console.log(e);
      });
    this.tableLoading = false;
    this.filtredTable = this.activeData;

    this.schoolDetails = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0];

    let { start, end } = this.settings.schoolarYears.find(
      (el) => el._id == this.settings.activeSchoolarYear
    );

    start = new Date(start).getFullYear();
    end = new Date(end).getFullYear();

    this.schoolarYearName = end + " / " + start;

    this.schoolType = this.user.building.filter(
      (el) => el.dbName == this.settings.activeBuilding
    )[0].type;
    if (!this.schoolType)
      notification.warning({
        message: this.$t("bulletin.typeIntrouvable"),
        description: this.$t("bulletin.saisirSchoolType"),
      });
    this.getTeachersAccess();
    // apiClient
    //   .get("/mark/classroomMarksStats/" + this.selectedTrimester + "/normal")
    //   .then(({ data }) => {
    //     this.classroomMarksStats = data;
    //   });
  },
  data() {
    // DONE
    return {
      visibleBulletinDate: false,
      bulletinDate: new Date(),
      updatedChanges: true,
      printLoading: false,
      syncLoading: false,
      syncSubjectLoading: false,
      visibleClassnameList: false,
      visibleSubjectList: false,
      schoolType: null,
      filtredTable: [],
      classroomMarksStats: {},
      selectedSubject: this.$t("scolarite.listeMatieres"),
      selectedSubjectName: "",
      bulletins: { students: [] },
      selectedClassName: "",
      markData: [],
      originalMarkData: [],
      activeModule: null,
      bulletinData: [],
      certifParams: [],
      moduleLoading: false,
      selectedTrimester: "1",
      selectedClasse: null,
      tableLoading: false,
      saveLoading: false,
      activeData: [],
      markRefs: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [],
      subsubjectData: [],
      listModules: [],
      selectedLevel: null,
      bulletinColors: {
        color1: [70, 187, 239],
        color2: [64, 187, 239],
        color3: [16, 169, 229],
        color4: [67, 62, 63],
        color5: [52, 172, 215],
        color6: [224, 237, 243],
        color7: [64, 187, 239],
        color8: [225, 225, 226],
        color9: [224, 237, 243],
      },
      schoolDetails: {},
      schoolarYearName: "",
      teacherAccess: [],
    };
  },
  // DONE
  beforeRouteLeave(to, from, next) {
    const _this = this;

    if (this.updatedChanges) next();
    else
      this.$confirm({
        title: this.$t("all.unsavedchanges"),
        content: this.$t("all.unsavedchangesContent"),
        okText: this.$t("all.oui"),
        okType: "danger",
        cancelText: this.$t("action.annuler"),
        onOk() {
          _this.updatedChanges = true;
          next();
        },
        onCancel() {
          next(false);
        },
      });
  },
  methods: {
    moment,
    getTeachersAccess() {
      apiClient
        .get(`/teacherAccess/${this.selectedTrimester}`)
        .then((res) => (this.teacherAccess = res.data))
        .catch((err) => console.error(err));
    },
    getSumMaxMArks(subsubjectData) {
      // DONE
      let sumMax = 0;
      subsubjectData.forEach((sub) => {
        sumMax += sub.maxMark;
      });
      return sumMax;
    },
    confirmLeaveReload(event) {
      // DONE
      if (!this.updatedChanges) {
        event.returnValue = this.$t("all.unsavedchangesContent");
      }
    },
    confirmeBulletinValidation() {
      // DONE
      const content = this.isValidatedBulletin()
        ? this.$t("bulletin.hideBulletin")
        : this.$t("bulletin.validateBulletin");
      this.$confirm({
        title: this.$t("all.sureTo"),
        content: (h) => <div style="color:red"> {content} </div>,
        onOk: () => {
          //console.log("OK");
          this.validateBulletin();
        },
        onCancel: () => {
          //console.log("Cancel");
        },
        class: "test",
      });
    },
    isValidatedBulletin() {
      // DONE
      if (this.selectedClasse) {
        const classsroom = this.classes.find(
          (item) => item._id === this.selectedClasse
        );
        const bulletin = classsroom
          ? classsroom[this.bulletinType]
          : { sem1: false, sem2: false, sem3: false };
        if (this.selectedTrimester == 1 && bulletin.sem1 == true) return true;
        else if (this.selectedTrimester == 2 && bulletin.sem2 == true)
          return true;
        else if (this.selectedTrimester == 3 && bulletin.sem3 == true)
          return true;
      }
      return false;
    },
    validateBulletin() {
      // DONE
      const isValidated = this.isValidatedBulletin();
      const classsroom = this.classes.find(
        (item) => item._id === this.selectedClasse
      );
      const bulletin = classsroom
        ? classsroom[this.bulletinType]
        : { sem1: false, sem2: false, sem3: false };
      if (this.selectedTrimester == 1) bulletin.sem1 = !bulletin.sem1;
      if (this.selectedTrimester == 2) bulletin.sem2 = !bulletin.sem2;
      if (this.selectedTrimester == 3) bulletin.sem3 = !bulletin.sem3;

      const bulletinField =
        this.type === "MP"
          ? "bulletin"
          : this.type === "MT"
          ? "bulletinSpec"
          : "bulletinVerySpec";
      apiClient
        .patch("/classrooms/" + this.selectedClasse, {
          data: {
            [bulletinField]: bulletin,
          },
          bulletin: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            avaliable: !isValidated,
            type: this.bulletinType,
          },
        })
        .then(() => {
          this.classes = this.classes.map((item) => {
            if (item._id === this.selectedClasse)
              item[this.bulletinType] = bulletin;
            return item;
          });

          if (!isValidated)
            this.$message.success(
              this.$t("bulletin.validated", { name: this.selectedClassName })
            );
          else
            this.$message.success(
              this.$t("bulletin.unvalidated", { name: this.selectedClassName })
            );
        });
    },
    getCertificationNameByMoyenne(moyenne) {
      // DONE
      for (const cert of this.certifParams)
        if (
          Number(moyenne) >= Number(cert.min) &&
          Number(moyenne) <= Number(cert.max)
        ) {
          return cert.name;
        }

      return "";
    },
    async sleep(timeout) {
      await new Promise((r) => setTimeout(r, timeout));
    },
    async chooseBulletinDate() {
      // GOOD
      this.visibleBulletinDate = true;
      return new Promise(async (res) => {
        while (this.visibleBulletinDate) {
          await this.sleep(500);
        }
        res();
      });
    },
    async printAllBulletinSpec() {
      try {
        this.printLoading = true;
        let config = {
          selectedLevel: this.selectedLevel,
          schoolDetails: this.schoolDetails,
          bulletinDate: this.bulletinDate,
          certifs: this.certifParams,
          selectedClassName: this.selectedClassName,
          schoolarYearName: this.schoolarYearName,
          bulletinType: this.type,
          selectedTrimester: this.selectedTrimester,
          settings: this.settings,
          selectedClasse: this.selectedClasse,
        };
        await bulletinHeplers[this.type].printAllBulletin(false, config);
      } catch (error) {
        console.error(error);
        this.$message.error(this.$t("error.erreur"));
      } finally {
        this.printLoading = false;
      }
    },
    async getModules(level) {
      await apiClient
        .post("/bulletin/v2/module/filter/marks", {
          status: "active",
          level: level,
          trimester: this.selectedTrimester,
          type: this.type,
        })
        .then((res) => {
          this.subjectsLoading = true;
          this.listModules = [];
          if (res.data.length == 0)
            return this.$message.warning(this.$t("error.aucModule"));
          res.data.forEach((moduleItem) => {
            moduleItem.subjects = moduleItem.subjects.map((subject) => {
              const subjectsInfo = moduleItem.subjectsInfo;
              const subsubjects = subjectsInfo[subject._id];
              subject.moduleName = moduleItem.name;
              if (subsubjects && subsubjects.sousModule)
                subject.subModule = this.subModuleList.find(
                  (sm) => String(sm._id) == String(subsubjects.sousModule)
                );
              return subject;
            });
            this.listModules.push(moduleItem);
          });
          this.subjectsLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.erreur"));
          this.moduleLoading = false;
        });
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },

    async saveAll() {
      this.saveLoading = true;
      // ! DEPRECATED DWEB-969
      // check if subsubject marks are completed
      // if (this.divided) {
      //   const subsubjects = this.subsubjectData.map((s) => String(s._id));
      //   for (const student of this.activeData) {
      //     const marks = this.markData.filter((m) => {
      //       return (
      //         m.student == student._id &&
      //         m.divided &&
      //         subsubjects.includes(m.subsubject)
      //       );
      //     });
      //
      //     if (marks.length != 0 && marks.length != subsubjects.length) {
      //       this.saveLoading = false;
      //       return this.$message.warning(
      //         this.$t("warning.missedSubsubjects", { name: student.fullName })
      //       );
      //     }
      //   }
      // }

      // check if marks are correct
      let thereError = false;
      for (const mark of this.markData) {
        if (!mark.divided) {
          if (isNaN(Number(mark.mark)) || mark.mark > 20 || mark.mark < 0) {
            thereError = true;
            break;
          }
        } else if (mark.divided) {
          const subsubject = this.subsubjectData.find(
            (s) => s._id == mark.subsubject
          );
          if (subsubject)
            if (
              isNaN(Number(mark.mark)) ||
              mark.mark > subsubject.maxMark ||
              mark.mark < 0
            ) {
              thereError = true;
              break;
            }
        }
      }

      if (thereError) {
        this.$message.warning(this.$t("warning.notCorrectMarks"));
        this.saveLoading = false;
        return;
      }

      // saving
      const toAddData = [];

      for (const mark of this.markData) {
        const originalMark = this.originalMarkData.find(
          (m) =>
            m.student == mark.student &&
            ((!m.divided && m.subject == mark.subject) ||
              (m.divided && m.subsubject == mark.subsubject))
        );
        if (originalMark) {
          if (Number(mark.mark) != Number(originalMark.mark))
            await apiClient
              .patch("/bulletin/v2/mark/" + originalMark._id, {
                data: {
                  mark: mark.mark,
                },
              })
              .then()
              .catch((err) => console.log(err));
        } else {
          const obj = {
            divided: mark.divided,
            student: mark.student,
            mark: mark.mark,
            trimester: this.selectedTrimester,
            classroom: this.selectedClasse,
            subject: mark.subject,
            type: "one",
          };
          if (mark.divided) obj.subsubject = mark.subsubject;
          toAddData.push(obj);
        }
      }

      await apiClient
        .put("/bulletin/v2/mark/many", {
          data: toAddData,
        })
        .then()
        .catch((err) => console.log(err));

      for (const originalMark of this.originalMarkData) {
        const mark = this.markData.find(
          (m) =>
            m.student == originalMark.student &&
            ((!m.divided && m.subject == originalMark.subject) ||
              (m.divided && m.subsubject == originalMark.subsubject))
        );
        if (!mark)
          await apiClient
            .delete("/bulletin/v2/mark/" + originalMark._id)
            .then()
            .catch((err) => console.log(err));
      }

      await this.updateMarks();

      this.saveLoading = false;
      this.updatedChanges = true;
      this.$message.success(this.$t("success.noteToutesEnregistre"));
    },

    deleteMark(id, studentId, divided) {
      this.markData = this.markData.filter(
        (m) =>
          !(
            m.student == studentId &&
            m.divided == divided &&
            (m.subsubject == id || m.subject == id)
          )
      );
      this.updatedChanges = false;
    },

    handleMarkChange(newMark, subjectId, subSubjectId, studentId, divided) {
      const mark = this.markData.find(
        (m) =>
          m.student == studentId &&
          ((divided && m.subsubject == subSubjectId) ||
            (!divided && m.subject == subjectId))
      );
      if (mark) mark.mark = Number(newMark);
      else {
        const obj = {
          student: studentId,
          mark: Number(newMark),
          divided: divided,
          subject: subjectId,
        };
        if (divided) obj.subsubject = subSubjectId;

        this.markData.push(obj);
      }

      this.updatedChanges = false;
    },

    getMarkValue(id, studentId, divided) {
      const mark = this.markData.find(
        (m) =>
          m.student == studentId &&
          m.divided == divided &&
          (m.subsubject == id || m.subject == id)
      );
      if (mark) return mark.mark;
      return null;
    },

    getTotalSubsubjects(record) {
      try {
        const subsubjects = this.subsubjectData.map((s) => String(s._id));
        const marks = this.markData.filter((m) => {
          return (
            m.student == record._id &&
            m.divided &&
            subsubjects.includes(m.subsubject)
          );
        });
        let total = 0;
        let subSubjectTotalCoefs = 0;
        marks.forEach((mark) => {
          let subsubjectData = this.subsubjectData.find(
            (s) => s._id == mark.subsubject
          );
          subSubjectTotalCoefs += subsubjectData.coefMark;
          total +=
            (mark.mark * (subsubjectData.coefMark ?? 1)) /
            subsubjectData.maxMark;
        });
        return total
          ? ((total * 20) / (subSubjectTotalCoefs ?? 1)).toFixed(2)
          : "";
      } catch (error) {
        console.log(error);
      }
    },

    bulletinTypeText(type) {
      switch (type) {
        case "MP":
          return this.$t("bulletin.bulletinPedagogique");
        case "MT":
          return this.$t("bulletin.bulletinSpecifique");
        case "MS":
          return this.$t("bulletin.bulletinVerySpecifique");
      }
    },
    disabledSubject(selectedSubject) {
      const subject = this.activeModule.subjects.find(
        (s) => s._id == selectedSubject
      );
      return subject ? subject.disabled : false;
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    async handleSubjectChange(val) {
      this.columns = [
        {
          title: this.$t("bulletin.nom"),
          dataIndex: "fullName",
          key: "fullName",
          scopedSlots: {
            customRender: "fullName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.fullName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
      ];

      this.subsubjectData = [];

      const subsubjects = this.activeModule.subjectsInfo[this.selectedSubject];
      if (
        subsubjects &&
        subsubjects.subsubjectsMark &&
        !this.isEmpty(subsubjects.subsubjectsMark)
      ) {
        this.divided = true;
        for (const sub of Object.keys(subsubjects.subsubjectsMark)) {
          const s = this.activeModule.subsubjects.find(
            (s) => String(s._id) == sub
          );
          this.columns.push({
            title: s.name,
            dataIndex: s._id,
            scopedSlots: { customRender: s._id },
          });
          this.subsubjectData.push({
            name: s.name,
            _id: s._id,
            divided: true,
            maxMark: subsubjects.subsubjectsMark[sub].max,
            coefMark: subsubjects.subsubjectsMark[sub].coef,
          });
        }
      } else {
        this.divided = false;
      }

      this.columns.push({
        title: "Note",
        dataIndex: "note",
        scopedSlots: { customRender: "note" },
      });
      // TODO This usefull or not
      this.updateMarks();
    },

    async updateMarks() {
      this.tableLoading = true;
      await apiClient
        .post("/bulletin/v2/mark/filter", {
          query: {
            classroom: this.selectedClasse,
            trimester: this.selectedTrimester,
            type: {
              $in: ["one"],
            },
          },
        })
        .then(({ data }) => {
          this.markData = [];
          this.originalMarkData = [];
          this.originalMarkData = data;
          data.map((mark) => {
            this.markData.push({
              student: mark.student,
              subject: mark.subject,
              subsubject: mark.subsubject,
              mark: mark.mark,
              divided: mark.divided,
            });
          });
        })
        .finally(() => (this.tableLoading = false));
    },

    async handleClassChange(on, value) {
      this.markRefs = [];
      this.selectedSubject = "";
      this.moduleLoading = true;
      this.selectedClasse = value;
      this.selectedClassName = this.classes.find(
        (item) => item._id === value
      ).name;
      this.selectedLevel = this.classes.find(
        (item) => item._id === value
      ).level;
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] = value;
      await apiClient
        .post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $set: {
                classRoom: {
                  $convert: {
                    input: `$schoolarYearsHistory.${this.settings.activeSchoolarYear}`,
                    to: "objectId",
                    onError: null,
                    onNull: null,
                  },
                },
              },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classRoom",
                foreignField: "_id",
                as: "classroom",
              },
            },
            {
              $project: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                firstNameLower: { $toLower: "$firstName" },
                gender: 1,
                classroom: {
                  _id: 1,
                  name: 1,
                },
              },
            },
            {
              $sort: {
                firstNameLower: 1,
              },
            },
          ],
        })
        .then(({ data }) => {
          this.rowData = [];
          data.map((elem) => {
            try {
              this.rowData.push({
                _id: elem._id,
                key: elem._id,
                fullName: elem.firstName + " " + elem.lastName,
                classroom: elem.classroom[0].name,
                classroom_id: elem.classroom[0]._id,
                gender: elem.gender,
              });
            } catch {}
          });
          this.activeData = this.rowData;
          this.tableLoading = false;
          this.moduleLoading = false;
          this.filtredTable = this.activeData;
        });
      //get modules
      this.getModules(this.selectedLevel);
      this.updateMarks();
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    async printFiche(withGrades = false) {
      this.printLoading = true;
      const subject = this.activeModule.subjects.find(
        (s) => s._id == this.selectedSubject
      );
      let teacherName = "";
      try {
        const teacher = this.teacherAccess.find((teacher) =>
          teacher.access.some(
            (access) =>
              // Check if teacher has access to this classroom
              access.classeRoom._id === this.selectedClasse &&
              // Check if teacher has access to this subject
              access.subjects.some((sub) => sub._id === this.selectedSubject)
          )
        );
        if (teacher) {
          teacherName = teacher.fullName;
        }
      } catch (error) {}

      this.$gtag.event(withGrades ? "Imp fich remplie" : "Imp fich vide", {
        event_category: "Impression PDF",
        event_label: "Bulletin specifique-NotesModules section",
        value: 1,
      });

      this.printLoading = true;
      var doc = new JsPDF({
        orientation: "p",
        unit: "mm",
        format: "a3",
      });
      const pdf_width = doc.internal.pageSize.width;

      let schoolDetails = this.user.building.find(
        (el) => el.dbName == this.settings.activeBuilding
      );
      let schoolName = schoolDetails.ArabicName
        ? schoolDetails.ArabicName
        : schoolDetails.name;
      let { start, end } = this.settings.schoolarYears.find(
        (el) => el._id == this.settings.activeSchoolarYear
      );

      start = new Date(start).getFullYear();
      end = new Date(end).getFullYear();

      //console.log(this.schoolarYearName);
      let schoolarYearName = end + " / " + start;
      let trimesterStr = "الثلاثي ";
      if (this.selectedTrimester == "1") trimesterStr += "الأول";
      if (this.selectedTrimester == "2") trimesterStr += "الثاني";
      if (this.selectedTrimester == "3") trimesterStr += "الثالث";

      let className = this.classes.find(
        (el) => el._id == this.selectedClasse
      ).name;
      className = className ? className : "";
      let date = new Date();
      doc.setFontSize(14);
      doc.setFont("Amiri", "Bold");
      let formattedDate = moment(date)
        .locale("ar-tn")
        .format("dddd ، D MMMM ، YYYY");
      doc.text(15, 15, formattedDate ? formattedDate : "");

      let width1 = doc.getTextWidth(`: المدرسة `);
      doc.text(pdf_width - width1 - 15, 15, ` : المدرسة `);
      let width2 = doc.getTextWidth(schoolName);
      doc.text(pdf_width - width1 - width2 - 15, 15, schoolName);

      width1 = doc.getTextWidth(`${schoolarYearName} : السنة الدراسية`);
      doc.text(
        pdf_width - width1 - 15,
        22,
        `${schoolarYearName} : السنة الدراسية`
      );

      doc.text(
        pdf_width - doc.getTextWidth(trimesterStr) - 15,
        29,
        trimesterStr
      );
      if (teacherName) {
        width1 = doc.getTextWidth(`: المعلم `);
        doc.text(pdf_width - width1 - 15, 36, ` : المعلم `);
        width2 = doc.getTextWidth(teacherName);
        doc.text(pdf_width - width1 - width2 - 15, 36, teacherName);
      }
      doc.setFontSize(22);
      doc.setTextColor(0, 0, 0);

      doc.text(pdf_width / 2 - 15, 15, "قائمة الأعداد");
      doc.setFontSize(18);
      width1 = doc.getTextWidth(className);

      doc.text(pdf_width / 2 - width1 / 2, 22, className);

      /********start table */
      let numberOfCols = 1;
      let subSubjectArray = [];
      let devided = subject.subsubjectData && subject.subsubjectData.length;
      if (devided) {
        numberOfCols = subject.subsubjectData.length + 1;

        subSubjectArray = subject.subsubjectData.map((s) => ({
          content: s.name + " \n/" + s.maxMark,
          halign: "center",
        }));
      }
      subSubjectArray.unshift({
        content: "العدد /20",
        styles: {
          textColor: [0, 0, 0], // RGB for black
        },
      });
      const header = [
        [
          {
            content: subject.name,
            colSpan: numberOfCols,
            styles: { halign: "center", textColor: [0, 0, 0], fontSize: 12 },
          },
          {
            content: "قائمة التلاميذ",
            rowSpan: 2,
            styles: { fontSize: 12 },
          },
          {
            content: "العدد \nالرتبي",
            rowSpan: 2,
            styles: {
              fontSize: 12,
              cellWidth: doc.getTextWidth("العدد الرتبي"),
            },
          },
        ],
        subSubjectArray,
      ];
      let array = [];
      let index = 1;

      this.activeData.forEach((student) => {
        let data = [];
        if (subject.divided) {
          subject.subsubjectData.forEach((s) => {
            const mark = this.getMarkValue(s._id, student._id, true) || "";

            data.push(withGrades ? mark : "");
          });
          data.unshift(withGrades ? this.getTotalSubsubjects(student) : "");
        } else {
          const mark = this.getMarkValue(subject._id, student._id, false) || "";
          data.unshift(withGrades ? mark : "");
        }
        data.push({
          content: student.fullName,
          styles: {
            halign: "right",
          },
        });
        data.push({
          content: index,
          styles: {
            halign: "right",
          },
        });
        index += 1;
        array.push(data);
      });

      doc.autoTable({
        theme: "grid",
        startY: 45,
        styles: {
          fontSize: 12,
          font: "Amiri",
          lineColor: [13, 12, 12],
          lineWidth: 0.05,
        },
        headStyles: {
          fontSize: 12,
          font: "Amiri",
          fontStyle: "Bold",
          fillColor: [245, 245, 245], // RGB for light gray
          textColor: [0, 0, 0],
        },
        bodyStyles: {
          fontSize: 12,
        },
        cellPadding: 0,
        body: array,
        head: header,
        margin: { top: 30 },
        didParseCell: function (data) {
          const columnIndex = data.column.index;
          const totalColumns = data.table.columns.length;
          if (data.row.index >= 0 && data.cell.section === "body") {
            const fixedHeight = 13; // Set the fixed row height
            const posY = data.cell.y + fixedHeight; // Calculate the new position for the row
            data.row.height = fixedHeight;
            doc.rect(
              data.cell.x,
              data.cell.y,
              data.cell.width,
              fixedHeight,
              "S"
            ); // Draw the cell manually
          }
          if (data.section == "head") {
            data.cell.styles.font = "Amiri";
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.halign = "center";
          }

          if (columnIndex === 0) {
            data.cell.styles.fillColor = [232, 232, 232]; // RGB for gray
            data.cell.styles.fontStyle = "bold";
          }
          if (columnIndex < totalColumns - 2) {
            data.cell.styles.halign = "center";
          }
          data.cell.styles.valign = "middle";
        },
      });

      this.printLoading = false;

      doc.save(className + " - قائمة أعداد تلاميذ.pdf");
    },
    async exportToExcel(withGrades = false) {
      this.printLoading = true;

      const subject = this.activeModule.subjects.find(
        (s) => s._id == this.selectedSubject
      );

      let className = this.classes.find(
        (el) => el._id == this.selectedClasse
      ).name;
      className = className ? className : "";

      let subSubjectArray = [];
      let divided = subject.subsubjectData && subject.subsubjectData.length;
      if (divided) {
        subSubjectArray = subject.subsubjectData.map(
          (s) => `${s.name} / ${s.maxMark}`
        );
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("أعداد التلاميذ");

      worksheet.addRow([
        subject.name,
        ...Array(subSubjectArray.length).fill(""),
        "قائمة التلاميذ",
        "العدد الرتبي",
      ]);
      worksheet.addRow(["20 / العدد", ...subSubjectArray, "", ""]);

      worksheet.mergeCells(1, 1, 1, subSubjectArray.length + 1);
      worksheet.mergeCells(
        1,
        subSubjectArray.length + 2,
        2,
        subSubjectArray.length + 2
      );
      worksheet.mergeCells(
        1,
        subSubjectArray.length + 3,
        2,
        subSubjectArray.length + 3
      );

      worksheet.getRow(1).eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "D9D9D9" },
        };
        cell.alignment = { horizontal: "center", vertical: "middle" };
        cell.font = { bold: true };
      });

      worksheet.getRow(2).eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "F2F2F2" },
        };
        cell.alignment = { horizontal: "center", vertical: "middle" };
        cell.font = { bold: true };
      });

      let index = 1;
      this.activeData.forEach((student) => {
        const row = [];

        if (subject.divided) {
          row.push(withGrades ? this.getTotalSubsubjects(student) : "");
          subject.subsubjectData.forEach((s) => {
            const mark = this.getMarkValue(s._id, student._id, true) || "";
            row.push(withGrades ? mark : "");
          });
        } else {
          const mark = this.getMarkValue(subject._id, student._id, false) || "";
          row.push(withGrades ? mark : "");
        }

        row.push(student.fullName);
        row.push(index);
        worksheet.addRow(row);

        worksheet
          .getRow(worksheet.lastRow.number)
          .eachCell((cell, colNumber) => {
            if (colNumber === row.length - 1 || colNumber === row.length) {
              cell.alignment = { horizontal: "right", vertical: "middle" };
            } else {
              cell.alignment = { horizontal: "center", vertical: "middle" };
            }
          });

        index += 1;
      });

      worksheet.columns = [
        { width: 25 },
        ...subSubjectArray.map(() => ({ width: 25 })),
        { width: 25 },
        { width: 10 },
      ];

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${className} - قائمة أعداد تلاميذ.xlsx`;
      link.click();

      this.printLoading = false;
    },

    async exportAllToExcel(withGrades = false) {
      this.printLoading = true;

      try {
        const subjects = this.listModules.flatMap((module) => module.subjects);
        let className =
          this.classes.find((el) => el._id == this.selectedClasse)?.name || "";

        const workbook = new ExcelJS.Workbook();

        let rowIndex = 0;

        const sanitizeSheetName = (name) => {
          return name.replace(/[\\\/\?\*\[\]:]/g, "_");
        };

        for (let i = 0; i < subjects.length; i++) {
          const subject = subjects[i];

          if (i > 0) {
            rowIndex += 5;
          }

          let subSubjectArray = [];
          let divided = subject.subsubjectData && subject.subsubjectData.length;
          if (divided) {
            subSubjectArray = subject.subsubjectData.map(
              (s) => `${s.name} / ${s.maxMark}`
            );
          }

          const worksheet = workbook.addWorksheet(
            sanitizeSheetName(subject.name)
          );

          worksheet.addRow([
            subject.name,
            ...Array(subSubjectArray.length).fill(""),
            "قائمة التلاميذ",
            "العدد الرتبي",
          ]);
          worksheet.addRow(["20 / العدد", ...subSubjectArray, "", ""]);

          worksheet.mergeCells(1, 1, 1, subSubjectArray.length + 1);
          worksheet.mergeCells(
            1,
            subSubjectArray.length + 2,
            2,
            subSubjectArray.length + 2
          );
          worksheet.mergeCells(
            1,
            subSubjectArray.length + 3,
            2,
            subSubjectArray.length + 3
          );

          worksheet.getRow(1).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "D9D9D9" },
            };
            cell.alignment = { horizontal: "center", vertical: "middle" };
            cell.font = { bold: true };
          });

          worksheet.getRow(2).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "F2F2F2" },
            };
            cell.alignment = { horizontal: "center", vertical: "middle" };
            cell.font = { bold: true };
          });

          let index = 1;
          this.activeData.forEach((student) => {
            const row = [];

            if (subject.divided) {
              row.push(withGrades ? this.getTotalSubsubjects(student) : "");
              subject.subsubjectData.forEach((s) => {
                const mark = this.getMarkValue(s._id, student._id, true) || "";
                row.push(withGrades ? mark : "");
              });
            } else {
              const mark =
                this.getMarkValue(subject._id, student._id, false) || "";
              row.push(withGrades ? mark : "");
            }

            row.push(student.fullName);
            row.push(index);
            worksheet.addRow(row);

            worksheet
              .getRow(worksheet.lastRow.number)
              .eachCell((cell, colNumber) => {
                if (colNumber === row.length - 1 || colNumber === row.length) {
                  cell.alignment = { horizontal: "right", vertical: "middle" };
                } else {
                  cell.alignment = { horizontal: "center", vertical: "middle" };
                }
              });

            index += 1;
          });

          worksheet.columns = [
            { width: 25 },
            ...subSubjectArray.map(() => ({ width: 25 })),
            { width: 25 },
            { width: 10 },
          ];

          rowIndex += worksheet.rowCount + 1;
        }

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${className} - قائمة أعداد تلاميذ.xlsx`;
        link.click();
      } catch (error) {
        console.error("Error while exporting to Excel:", error);
      } finally {
        this.printLoading = false;
      }
    },
    async printAllFiche(withGrades = false) {
      this.printLoading = true;
      try {
        //get all subjects for all modules
        const subjects = this.listModules.flatMap((item) => item.subjects);
        let className = this.classes.find(
          (el) => el._id == this.selectedClasse
        ).name;
        className = className ? className : "";
        var doc = new JsPDF({
          orientation: "p",
          unit: "mm",
          format: "a3",
        });

        const totalPages = subjects.length;

        for (let i = 0; i < subjects.length; i++) {
          const subject = subjects[i];
          if (i > 0) {
            doc.addPage();
          }
          let teacherName = "";

          try {
            const teacher = this.teacherAccess.find((teacher) =>
              teacher.access.some(
                (access) =>
                  access.classeRoom._id === this.selectedClasse &&
                  access.subjects.some((sub) => sub._id === subject._id)
              )
            );
            if (teacher) {
              teacherName = teacher.fullName;
            }
          } catch (error) {}

          const pdf_width = doc.internal.pageSize.width;

          let schoolDetails = this.user.building.find(
            (el) => el.dbName == this.settings.activeBuilding
          );
          let schoolName = schoolDetails.ArabicName
            ? schoolDetails.ArabicName
            : schoolDetails.name;
          let { start, end } = this.settings.schoolarYears.find(
            (el) => el._id == this.settings.activeSchoolarYear
          );

          start = new Date(start).getFullYear();
          end = new Date(end).getFullYear();

          let schoolarYearName = end + " / " + start;
          let trimesterStr = "الثلاثي ";
          if (this.selectedTrimester == "1") trimesterStr += "الأول";
          if (this.selectedTrimester == "2") trimesterStr += "الثاني";
          if (this.selectedTrimester == "3") trimesterStr += "الثالث";

          let date = new Date();
          doc.setFontSize(14);
          doc.setFont("Amiri", "Bold");
          let formattedDate = moment(date)
            .locale("ar-tn")
            .format("dddd ، D MMMM ، YYYY");
          doc.text(15, 15, formattedDate ? formattedDate : "");

          let width1 = doc.getTextWidth(`: المدرسة `);
          doc.text(pdf_width - width1 - 15, 15, ` : المدرسة `);
          let width2 = doc.getTextWidth(schoolName);
          doc.text(pdf_width - width1 - width2 - 15, 15, schoolName);

          width1 = doc.getTextWidth(`${schoolarYearName} : السنة الدراسية`);
          doc.text(
            pdf_width - width1 - 15,
            22,
            `${schoolarYearName} : السنة الدراسية`
          );

          doc.text(
            pdf_width - doc.getTextWidth(trimesterStr) - 15,
            29,
            trimesterStr
          );

          if (teacherName) {
            width1 = doc.getTextWidth(`: المعلم `);
            doc.text(pdf_width - width1 - 15, 36, ` : المعلم `);
            width2 = doc.getTextWidth(teacherName);
            doc.text(pdf_width - width1 - width2 - 15, 36, teacherName);
          }
          doc.setFontSize(22);
          doc.setTextColor(0, 0, 0);

          doc.text(pdf_width / 2 - 15, 15, "قائمة الأعداد");
          doc.setFontSize(18);
          width1 = doc.getTextWidth(className);

          doc.text(pdf_width / 2 - width1 / 2, 22, className);

          /********start table */
          let numberOfCols = 1;
          let subSubjectArray = [];
          let devided = subject.subsubjectData && subject.subsubjectData.length;
          if (devided) {
            numberOfCols = subject.subsubjectData.length + 1;

            subSubjectArray = subject.subsubjectData.map((s) => ({
              content: s.name + " \n/" + s.maxMark,
              halign: "center",
            }));
          }
          subSubjectArray.unshift({
            content: "العدد /20",
            styles: {
              textColor: [0, 0, 0], // RGB for black
            },
          });
          const header = [
            [
              {
                content: subject.name,
                colSpan: numberOfCols,
                styles: {
                  halign: "center",
                  textColor: [0, 0, 0],
                  fontSize: 12,
                },
              },
              {
                content: "قائمة التلاميذ",
                rowSpan: 2,
                styles: { fontSize: 12 },
              },
              {
                content: "العدد \nالرتبي",
                rowSpan: 2,
                styles: {
                  fontSize: 12,
                  cellWidth: doc.getTextWidth("العدد الرتبي"),
                },
              },
            ],
            subSubjectArray,
          ];
          let array = [];
          let index = 1;

          this.activeData.forEach((student) => {
            let data = [];
            if (subject.divided) {
              subject.subsubjectData.forEach((s) => {
                const mark = this.getMarkValue(s._id, student._id, true) || "";

                data.push(withGrades ? mark : "");
              });
              data.unshift(withGrades ? this.getTotalSubsubjects(student) : "");
            } else {
              const mark =
                this.getMarkValue(subject._id, student._id, false) || "";
              data.unshift(withGrades ? mark : "");
            }
            data.push({
              content: student.fullName,
              styles: {
                halign: "right",
              },
            });
            data.push({
              content: index,
              styles: {
                halign: "right",
              },
            });
            index += 1;
            array.push(data);
          });
          doc.autoTable({
            theme: "grid",
            startY: 45,
            styles: {
              fontSize: 12,
              font: "Amiri",
              lineColor: [13, 12, 12],
              lineWidth: 0.05,
            },
            headStyles: {
              fontSize: 12,
              font: "Amiri",
              fontStyle: "Bold",
              fillColor: [245, 245, 245], // RGB for light gray
              textColor: [0, 0, 0],
            },
            bodyStyles: {
              fontSize: 12,
            },
            cellPadding: 0,
            body: array,
            head: header,
            margin: { top: 30 },
            didParseCell: function (data) {
              const columnIndex = data.column.index;
              const totalColumns = data.table.columns.length;
              if (data.row.index >= 0 && data.cell.section === "body") {
                const fixedHeight = 13; // Set the fixed row height
                const posY = data.cell.y + fixedHeight; // Calculate the new position for the row
                data.row.height = fixedHeight;
                doc.rect(
                  data.cell.x,
                  data.cell.y,
                  data.cell.width,
                  fixedHeight,
                  "S"
                ); // Draw the cell manually
              }
              if (data.section == "head") {
                data.cell.styles.font = "Amiri";
                data.cell.styles.fontStyle = "bold";
                data.cell.styles.halign = "center";
              }

              data.cell.styles.valign = "middle";

              if (columnIndex === 0) {
                data.cell.styles.fillColor = [232, 232, 232]; // RGB for gray
                data.cell.styles.fontStyle = "bold";
              }
              if (columnIndex < totalColumns - 2) {
                data.cell.styles.halign = "center";
              }
            },
          });

          // After autoTable for each page, add page number
          doc.setFontSize(12);
          doc.setFont("Amiri", "normal");
          const pageText = `${i + 1} / ${totalPages}`; // Format: current/total
          const textWidth = doc.getTextWidth(pageText);
          const pageWidth = doc.internal.pageSize.width;
          const pageHeight = doc.internal.pageSize.height;

          // Position the page number at the bottom center of the page
          doc.text(
            pageText,
            pageWidth / 2 - textWidth / 2,
            pageHeight - 10 // 10mm from bottom
          );
        }
        doc.save(`${className} - قائمة أعداد تلاميذ.pdf`);
      } catch (error) {
      } finally {
        this.printLoading = false;
      }
    },
  },
};
